<template>
    <div class="h-full">
        <div v-show="!config.isDetailDisplay"  :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
            <Control ref="control" :config="config"></Control>
        </div>
    </div>
</template>
<script>
export default {
    data(){
            return {
                config: {
                    isDetailDisplay:false,
                    dataSourceKey:null,
                    isAdd: false,
                    isCanEdit:false,
                },
                editDataSource: {
                },
                initialDataSource: {}
            }
        },
        methods:{
        },
        components:{
            "Control": resolve => { require(['./components/list.vue'], resolve) },
        }
}
</script>